import React from "react"

// import { Container } from './styles';

export default function Footer() {
  return (
    <footer
      className="fdb-block footer-large"
      style={{
        backgroundImage: "url(/fundo-rodape.png)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#02121E",
      }}
    >
      <div className="container">
        <div className="row align-items-top text-center text-md-left">
          <div className="col-12 col-md-4 mt-5 mt-md-0 text-md-left">
            <h3 className="text-light">
              <strong>Sobre nós</strong>
            </h3>
            <p>
              A Revo Brasil foi criada com o propósito de oferecer aos seus
              clientes e parceiros no ramo imobiliário um serviço diferenciado
              de qualidade voltado para a obtenção de resultados, superando as
              expectativas do melhor investimento.
            </p>
          </div>

          <div className="col-12 col-sm-6 col-md-4">
            <h3 className="text-light">
              <strong>Onde nos encontrar</strong>
            </h3>
            <p>
              Rua XV de Novembro, n° 3758, Redentora
              <br />
              São José do Rio Preto - SP
            </p>
            <p>0800 878 7777</p>
          </div>

          <div className="col-12 col-sm-6 col-md-4">
            <img
              height="80"
              src="/revo-negocios-imobiliarios-b.svg"
              alt="Revo Brasil"
            />
            <div className="text-white mt-1">CRECI: 26009-J</div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col text-center text-light">
            &copy; Revo Brasil Negócio Imobiliários.
          </div>
        </div>
      </div>
    </footer>
  )
}
